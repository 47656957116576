import React from "react";

function Footer() {
  const PAGE_ID = {
    "CONTACT_US":1,
    "ABOUT_US":2,
	"ADVERTISE_WITH_US":3,
    "TERMS_AND_CONDITIONS":4,
    "PRIVACY":5,
  }


  return (
      <footer>
        <div className="container">
          <div className="row add_bottom_25">
            <div className="col-lg-6">

            </div>
            <div className="col-lg-6">
              <ul className="additional_links">
				<li><a href={"/page/" + PAGE_ID.ADVERTISE_WITH_US}>Advertise With Us</a></li>
                <li><a href={"/page/" + PAGE_ID.CONTACT_US}>Contact Us</a></li>
                <li><a href={"/page/" + PAGE_ID.ABOUT_US}>About Us</a></li>
                <li><a href={"/page/" + PAGE_ID.TERMS_AND_CONDITIONS}>Terms of Use</a></li>
                <li><a href={"/page/" + PAGE_ID.PRIVACY}>Privacy</a></li>
                <li><span>© Geo Domains</span></li>
              </ul>
            </div>
          </div>
        </div>
        </footer>
)
}



export default Footer
