import {Image, Card, Spin, Tag, Layout} from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import React, {useEffect} from "react";
import SearchDomainInput from "../search-domain-input/SearchDomainInputContainer";

function Header(props) {
  const domain = document.domain;
  const {Header, Content} = Layout;

  useEffect(()=>{

    if(!props.domainInfo && !props.domainFolder){
      props.getDomainInfo(domain, "")
    } else if (!props.domainInfo) {
      props.getDomainInfo(domain, props.domainFolder)
    }

  }, [])


  const { domainInfo, homeDomainInfo } = props


  const getBrand = () => {
    if(domainInfo){
      return domainInfo.brand
    }

    if(homeDomainInfo){
      return homeDomainInfo.brand
    }
    return  'ShareAustralia'

  }


  const getTitle = () => {
    console.log(props)
    if(domainInfo){
      return domainInfo.title
    }

    if(homeDomainInfo){
      return homeDomainInfo.title
    }

    return  'Share something in your Local Community'
  }



  return (
      <header className="header_in clearfix" style={{backgroundColor: domainInfo ? domainInfo.domainColor : ''}}>
        <div className="container">
          <div id="logo">
                   <a  onClick={()=>{

                     if((domainInfo.domainName == 'shareaustralia.com.au') && !domainInfo.domainFolder){
                       window.location.href = 'http://shareaustralia.com.au/'
                       return false
                     }



                   const url = 'http://' + domainInfo.domainName + '/' + (domainInfo.domainFolder ? domainInfo.domainFolder + '/home': 'home')


                window.location.href = url

                    }}>
                     <img height={50} style={{marginRight:10}}  src={'https://storage.googleapis.com/share-australia/images/SuburbLogo.png'} />

                      <span className={"headerTitle"}>  {getBrand() }</span>
                </a>
          </div>
          <div className={'sub_title'}> {getTitle() }</div>
          <nav className={"main-menu"}>
            <SearchDomainInput small />
          </nav>
        </div>
      </header>
  )

  // return (
  //     <Header className="header">
  //       <Row>
  //         <Col xs={4} style={{textIndent:20}} onClick={()=>{
  //           const url = 'http://' + domainInfo.domainName + '/' + (domainInfo.domainFolder ? domainInfo.domainFolder + '/home': 'home')
  //
  //           window.location.href = url
  //
  //         }}>
  //           <img style={{marginRight:10}}  src={'https://storage.googleapis.com/share-australia/images/SuburbLogo.png'} />
  //
  //           <span className={"headerTitle"}>  {domainInfo ?  domainInfo.brand :null }</span>
  //         </Col>
  //         {/*<Col xs={3} >{domainInfo ?  domainInfo.brand :null }</Col>*/}
  //         <Col xs={4} style={{textAlign:'center', lineHeight:'120%', verticalAlign:"middle",}}>
  //           {domainInfo ?  domainInfo.title :null }
  //         </Col>
  //         <Col xs={4} style={{textAlign:'right'}}>
  //           <SearchDomainInput small />
  //         </Col>
  //       </Row>
  //     </Header>
  //
  // )
}



export default Header
