import  {DOMAIN, LOADING} from '../actions/ActionTypes'

const initialState = {
  homeDomainInfo:null,
  homePopular:[],
  isLoading:false,
  searchDomainInfo:[],
  multipleDomainInfo:[]
}

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOMAIN.GET_HOME_DOMAIN_INFO_SUCCESS:{
      return {
        ...state,
        homeDomainInfo: action.payload.homeInfo,
        homePopular: action.payload.homePopular
      }
    }
    case DOMAIN.GET_DOMAIN_INFO_SUCCESS:{
      return {
        ...state,
        domainInfo: action.payload.body.domainInfo,
        multipleDomainInfo: action.payload.body.multipleDomainInfo,
      }
    }
    case DOMAIN.SEARCH_DOMAIN_INFO_SUCCESS:{
      return {
        ...state,
        searchDomainInfo: action.payload,
      }
    }
    case LOADING.START:{
      return {
        ...state,
        isLoading: true,
      }
    }
    case LOADING.END:{
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state

  }
}
export default domainReducer
