import './styles/App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Routers from './routers'
import { Provider } from 'react-redux'
import store from './store'





function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <Routers />
      </Provider>
    </div>
  );
}

export default App;
