const GLOBAL_DOMAIN = 'localhost'


export function isHomePage(domainName) {
  const domain = document.domain;

  if(GLOBAL_DOMAIN == domain){
    return true;
  }

  return false;
}

export function getDomainName() {
  const domain = document.domain.split('.');

  let result = '';

  domain.forEach( (str, index) => {
    if(str == 'www' && index == 0){
      return false
    }

    result = result == '' ? result+str : result + '.' + str

  } )


  return result
}
