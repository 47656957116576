import { connect } from 'react-redux'
import CreatePost from './CreatePost'
import {createPost} from '../../actions/postAction'
import {createTag} from '../../actions/tagAction'

function mapStateToProps(state) {
  return {
    domainInfo:state.domainReducer.domainInfo,
    // isLoading:state.domainReducer.isLoading,
    // tagList:state.tagReducer.tagList,
    ...state.postReducer,
    newTagId:state.tagReducer.newTagId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getDomainInfo: (domainName, domainFolder) => dispatch(getDomainInfo(domainName, domainFolder)),
    // getTagList: () => dispatch(getTagList()),
    createPost: (params, fun) => dispatch(createPost(params, fun)),
    createTag: (tagName) => dispatch(createTag(tagName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)
