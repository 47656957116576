import {api} from "../untils/api";
import {LOADING, POST} from './ActionTypes'

export function getPostList(domainId, tagId = 0, page = 0, pageSize = 50) {
  return dispatch => {
    dispatch(getPostListStart())
    api.get('/post/list?domainId=' + domainId + "&tagId=" + (tagId == 8 ? 0 :tagId) + "&page=" + page + "&pageSize=" + pageSize) .then(response => {
      dispatch(loadingPostListSuccess(response.data))
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}

export function getPost(postId) {
  return dispatch => {
    api.get('/post?postId=' + postId) .then(response => {
      dispatch(loadingPostSuccess(response.data))
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}




export function createPost(params, fun) {
  return dispatch => {
    dispatch(createPostStart())
    api.post('/post/create', params).then(response => {
      dispatch(createPostEnd())
      dispatch(createPostSuccess())
      fun && fun(response.data.body)
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}

export function clearPost() {
  return dispatch => {
    dispatch({type:POST.POST_CLEAR})
  }
}




function loadingPostSuccess(data) {
  return {
    type: POST.GET_POST_SUCCESS,
    payload: data,
  }
}



function loadingPostListSuccess(data) {
  return {
    type: POST.GET_POST_LIST_SUCCESS,
    payload: data,
  }
}

function createPostSuccess() {
  return {
    type: POST.CREATE_POST_SUCCESS,
  }
}

function createPostStart() {
  return {
    type: POST.CREATE_POST_START,
  }
}

function createPostEnd() {
  return {
    type: POST.CREATE_POST_END,
  }
}

function getPostListStart() {
  return {
    type: POST.GET_POST_LIST_START,
  }
}
