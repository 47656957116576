import {api} from '../untils/api'
import  {DOMAIN, LOADING} from './ActionTypes'


export function getDomainInfo(domainName, domainFolder) {
  return dispatch => {
    dispatch(loadingStart())
    api.get('/domain/info?domainName=' + domainName + '&domainFolder=' + domainFolder).then(response => {
      dispatch(loadingDomainSuccess(response.data))
      dispatch(loadingEND())
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}


export function getHomeInfo() {

  return dispatch => {
    dispatch(loadingStart())
    api.get('/home/info').then(response => {
      dispatch(loadingHomeSuccess(response.data.body))
      dispatch(loadingEND())
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}


export function searchHomeInfo(searchString) {

  return dispatch => {
    dispatch(searchDomainStart())
    api.get('/domain/search?searchString=' + searchString).then(response => {
      console.log(response.data)
      dispatch(searchDomainSuccess(response.data.body))
      dispatch(searchDomainEND())
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}


function searchDomainSuccess(data) {
  return {
    type: DOMAIN.SEARCH_DOMAIN_INFO_SUCCESS,
    payload: data,
  }
}




function loadingHomeSuccess(data) {
  return {
    type: DOMAIN.GET_HOME_DOMAIN_INFO_SUCCESS,
    payload: data,
  }
}

function loadingDomainSuccess(data) {
  return {
    type: DOMAIN.GET_DOMAIN_INFO_SUCCESS,
    payload: data,
  }
}


function loadingStart() {
  return {
    type: LOADING.START,
  }
}

function loadingEND() {
  return {
    type: LOADING.END,
  }
}

function searchDomainStart() {
  return {
    type: LOADING.SEARCH_DOMAIN_START,
  }
}

function searchDomainEND() {
  return {
    type: LOADING.SEARCH_DOMAIN_END,
  }
}
