import  {SINGLE_PAGE} from '../actions/ActionTypes'

const initialState = {
  singlePageInfo:null,
}

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_PAGE.GET_SINGLE_PAGE_SUCCESS:{
      return {
        ...state,
        singlePageInfo: action.payload.body,
      }
    }
    default:
      return state

  }
}
export default tagReducer
