import {api} from "../untils/api";
import { TAG} from './ActionTypes'

export function getTagList() {
  return dispatch => {
    api.get('/tag/list').then(response => {
      dispatch(loadingTagListSuccess(response.data))
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}


export function createTag(tagName) {
  return dispatch => {
    api.post('/tag/create', {tagName:tagName}).then(response => {
      dispatch(createTagSuccess(response.data.body))
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}






function loadingTagListSuccess(data) {
  return {
    type: TAG.GET_TAG_LIST_SUCCESS,
    payload: data,
  }
}


function createTagSuccess(data) {
  return {
    type: TAG.CREATE_TAG_SUCCESS,
    payload: data,
  }
}
