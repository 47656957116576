import { connect } from 'react-redux'
import SinglePage from './SinglePage'
import {getSinglePage} from "../../actions/singlePageAction";

function mapStateToProps(state) {
  return {
    domainInfo:state.domainReducer.domainInfo,
    currentPost:state.postReducer.currentPost,
    singlePageInfo:state.singlePageReducer.singlePageInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSinglePage: (id) => dispatch(getSinglePage(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePage)
