import { connect } from 'react-redux'
import Posts from './Posts'
import {getDomainInfo} from "../../actions/domainAction";
import {getTagList} from "../../actions/tagAction";
import {clearPost, getPostList} from "../../actions/postAction";

function mapStateToProps(state) {
  return {
    domainInfo:state.domainReducer.domainInfo,
    // isLoading:state.domainReducer.isLoading,
    // tagList:state.tagReducer.tagList,
    postList:state.postReducer.postList,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getDomainInfo: (domainName, domainFolder) => dispatch(getDomainInfo(domainName, domainFolder)),
    // getTagList: () => dispatch(getTagList()),
    getPostList: (domainId, tagId, page, pageSize) => dispatch(getPostList(domainId, tagId, page, pageSize)),
    clearPost: () => dispatch(clearPost()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Posts)
