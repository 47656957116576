import {Layout, List, Card, Tag, Tabs, Button, Breadcrumb, BackTop, Divider, Spin} from 'antd';
import '../../styles/styles.css'

import React, { useEffect, useState } from 'react'

import Post from '../../components/post'

import Header from '../../components/header/HeaderContainer'

import {  Row, Col, Container } from 'react-bootstrap';
import {Adsense} from '@ctrl/react-adsense';
import Footer from "../../components/footer";


function Posts(props) {


  const { Content} = Layout;

  const [domainInfo] = useState(props.history.location.state.domainInfo)
  const [isMorePost, setIsMorePost] = useState(true)
  const [postList, setPostList] = useState([])
  const [pageSize] = useState(100)


  useEffect(()=>{
    props.getPostList(domainInfo.id, props.match.params.tagId, 1, pageSize)

  }, [])


  useEffect(()=>{

    if(JSON.stringify(props.postList) != "[]"){

      setPostList(postList.concat(props.postList))

      if(props.postList.length < pageSize){
        setIsMorePost(false)
      } else {
        setIsMorePost(true)
      }

      props.clearPost()


    }
  }, [props.postList])




  const renderLoadingButton = () => {

    if(!isMorePost){
      return  null
    }


    if(props.postLoading){
      return  (
          <Divider  plain>
            <Spin size={'large'} />
          </Divider>
      )
    }



    return (
        <Divider  plain>
          <Button shape="round"  type="primary"  onClick={()=>{
            console.log(postList.length/pageSize + 1)
            props.getPostList(props.domainInfo.id, 0, postList.length/pageSize + 1, pageSize)
          }} style={{ fontWeight:'bold', backgroundColor:'#589442', borderColor:'#589442'}}>
            Show More
          </Button>
        </Divider>
    )
  }



  const renderPostList = () => {


    const result = []

    postList.forEach(  post => {
      result.push(  <div className={'col-md-5'}>
        <Post post={post} onClick={()=>{

          const params = {
            post: post,
            domainInfo:props.domainInfo
          }

          const pre_url = props.match.params.domainFolder ? '/' + props.match.params.domainFolder  : ''

          const path = {
            pathname:pre_url + '/postDetail/' + post.id,
            state:params,
          }

          props.history.push(path);
        }
        }/>
      </div>)
    } )

    return (
        <Row>
          {result}
          {renderLoadingButton()}
        </Row>
    )
  }

  let setScrollTop = scrollTop => {
    // Different browsers treat this differently, so set all
    window.scrollTop = scrollTop;
    window.document.body.scrollTop = scrollTop;
    window.document.documentElement.scrollTop = scrollTop;
  }

  const renderGoogleAds = () => {
    if(!props.domainInfo || !props.domainInfo.googleAds){
      return null
    }
    return (
        <Adsense
            client='ca-pub-0604507048194960'
            slot='5599067266'
        />
    )
  }


  const renderBody = () => {
    return (
        <div>
          <div className="page_header element_to_stick">
            <div className="container">
              <div className="row" style={{textAlign:"left"}}>
                <div className="col-xl-2 col-lg-3 col-md-3 d-none d-md-block">
                  <div className="breadcrumbs">
                    <ul>
                      <li><a style={{textDecoration:'underline'}} onClick={()=>{
                        const url = 'http://' + domainInfo.domainName + '/' + (domainInfo.domainFolder ? domainInfo.domainFolder + '/home': 'home')

                        window.location.href = url + '?redirect=true'

                      }}>Home</a></li>
                      <li>{props.match.params.tagDisplayName}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-7  ">
                </div>
              </div>
            </div>
          </div>


          <div className={'container margin_detail'}>
            {renderGoogleAds()}
          </div>


          <div className={'container margin_detail'}>
            <Row>
              {renderPostList()}
            </Row>
          </div>
        </div>
    )

  }




  return (
      <div>
        <Header domainFolder={props.match.params.domainFolder} />
        {renderBody()}
        <Footer />
        <BackTop duration={20} />
      </div>
  )
}


export default Posts
