import  {TAG} from '../actions/ActionTypes'

const initialState = {
  tagList:[],
  newTagId:null
}

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case TAG.GET_TAG_LIST_SUCCESS:{
      return {
        ...state,
        tagList: action.payload.body,
      }
    }
    case TAG.CREATE_TAG_SUCCESS:{
      return {
        ...state,
        newTagId: action.payload,
      }
    }
    default:
      return state

  }
}
export default tagReducer
