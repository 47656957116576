import {Image} from 'antd';
import React, { useState } from 'react'

function Img(props) {

  const noneImg = "https://storage.googleapis.com/share-australia/images/No-Image-Placeholder.svg.png"

  return (
     <Image width={props.width} preview={false} height={props.height} src={props.src ? props.src  : noneImg}  bordered={props.bordered}
            fallback={noneImg}
     />
  )
}


export default Img
