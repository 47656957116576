import { combineReducers } from 'redux'
import tagReducer from './store/tagReducer'
import postReducer from './store/postReducer'
import domainReducer from './store/domainReducer'
import singlePageReducer from './store/singlePageReducer'

const appReducer = combineReducers({
  domainReducer,
  tagReducer,
  postReducer,
  singlePageReducer
})

const rootReducer = (state, action) => {
  let newState = state
  // if (action.type === 'AUTH/LOGOUT_SUCCESS') {
  //   newState = undefined
  // }

  return appReducer(newState, action)
}

export default rootReducer
