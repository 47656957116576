import {Layout, Button, Image, List, Divider, Spin,BackTop, useRef} from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import '../../styles/styles.css'
import SearchDomainInput from '../../components/search-domain-input/SearchDomainInputContainer'
import TagCard from '../../components/tag-card/TagCard'
import Loading from '../../components/loading'

import React, {useEffect, useState} from 'react'
import Post from "../../components/post";
import Footer from "../../components/footer";
import { getDomainName } from "../../untils/domain";

function Home(props) {

  const [postList, setPostList] = useState([])
  const [isMorePost, setIsMorePost] = useState(true)
  const [pageSize] = useState(40)


  useEffect(()=>{
    props.getHomeInfo();
    props.getPostList(1, 0, 1, pageSize)
  }, [])


  useEffect(()=>{
    const domain = getDomainName();
    console.log(domain)
    if(!props.isLoading && props.homeDomainInfo && domain != 'www.shareaustralia.com.au' && domain != 'shareaustralia.com.au'){
      props.history.push("/home");
    }


  }, [props.isLoading])


  let setScrollTop = scrollTop => {
    // Different browsers treat this differently, so set all
    window.scrollTop = scrollTop;
    window.document.body.scrollTop = scrollTop;
    window.document.documentElement.scrollTop = scrollTop;
  }


  useEffect(()=>{

    if(JSON.stringify(props.postList) != "[]"){

      setPostList(postList.concat(props.postList))

      if(props.postList.length < pageSize){
        setIsMorePost(false)
      } else {
        setIsMorePost(true)
      }

      props.clearPost()


    }
  }, [props.postList])



  const renderLoadingButton = () => {

    if(!isMorePost){
      return  (
          <Divider  plain>
            No More
          </Divider>
      )
    }


    if(props.postLoading){
        return  (
            <Divider  plain>
              <Spin size={'large'} />
            </Divider>
        )
    }



    return (
        <Divider  plain>
          <Button shape="round"  type="primary"  onClick={()=>{
            props.getPostList(1, 0, postList.length/pageSize + 1, pageSize)
          }} style={{ fontWeight:'bold', backgroundColor:'#589442', borderColor:'#589442'}}>
            Show More
          </Button>
        </Divider>
    )
  }






  const renderPopularList = () => {

    if(!props.homePopular){
      return null
    }

    const result = [];

    props.homePopular.forEach( popular => {
      result.push(
          <Col md={2} sm={4} xs={6} style={{marginBottom: 20}} key={popular.id}>
            <TagCard  imageUrl={popular.imageUrl} title={popular.title} onClick={()=>{
              window.location.href = popular.link;
            }} />

          </Col>
      )
    })

    return result
  }

  const renderPostCard = (post) => {

    return (
        <div className={'col-md-3'}>
          <Post post={post} onClick={()=>{

            const params = {
              post: post,
              domainInfo:props.domainInfo
            }

            const pre_url = props.match.params.domainFolder ? '/' + props.match.params.domainFolder  : ''

            const path = {
              pathname:pre_url + '/postDetail/' + post.id,
              state:params,
            }

            props.history.push(path);
          }
          }/>
        </div>
    )
  }

  const renderPostList = () => {

    const result = []

    postList.forEach(  post => {
      result.push(renderPostCard(post))
    } )


    return result
  }

  const { homeDomainInfo, isLoading } = props


  const renderBody = () => {
    if(isLoading){
      return (
          <Loading disabled={isLoading} />
      )
    }
    return (
        <div>


          <header className="header clearfix element_to_stick" style={{position:'absolute'}}>
            <div className="container">
              <div id="logo">
                <a href="#">
                  <img src={homeDomainInfo? homeDomainInfo.logoUrl : ''} height="50" alt="" className="logo_normal" />
                </a>
              </div>
            </div>
          </header>


          <div className="header-video">
            <div id="">
              <div className="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-xl-8 col-lg-10 col-md-8">
                      <h1 style={{marginTop:30, marginBottom:20}}>{homeDomainInfo ?  homeDomainInfo.title :null }</h1>
                      <SearchDomainInput />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img src="https://storage.googleapis.com/share-australia/images/index2.jpg" alt="" className="header-video--media" data-video-src="video/intro"
                 data-teaser-source="video/intro" data-provider="" />

          </div>


          <div className="bg_black">
            <div className="container margin_90_10">
              <div className="main_title center">
                <span><em></em></span>
                <h2>{homeDomainInfo ?  homeDomainInfo.data :null }</h2>


                  <Row style={{marginRight:3, marginLeft:3, marginTop:20}}>
                    {renderPopularList()}
                  </Row>
              </div>
            </div>
          </div>


          <div className={"container margin_90_10"}>
            <Row style={{marginRight:3, marginLeft:3}}>

              <Col className="col-3">
                <div className="main_title version_2" style={{textAlign:'left'}}>
                  <span><em></em></span>
                  <h2>Global Posts</h2>
                </div>
              </Col>


              <Row>
                {renderPostList()}

                {renderLoadingButton()}
              </Row>
            </Row>
          </div>
        </div>
    )

  }


  return (
      <div>
        {renderBody()}
        <Footer />
        <BackTop duration={40} />
      </div>

  )
}


export default Home
