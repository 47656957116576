import { connect } from 'react-redux'
import Header from './Header'
import {getDomainInfo, searchHomeInfo} from "../../actions/domainAction";

function mapStateToProps(state, props) {
  return {
    domainInfo:state.domainReducer.domainInfo ? state.domainReducer.domainInfo : state.domainReducer.homeDomainInfo,
    homeDomainInfo:state.domainReducer.homeDomainInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDomainInfo: (domainName, domainFolder) => dispatch(getDomainInfo(domainName, domainFolder)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
