import React from "react";
import Img from '../components/Image'
import Linkify from 'react-linkify';
import parse from "html-react-parser";

function Post(props) {

  const { post }  = props
  const regex = /(<([^>]+)>)/ig


  if(post.imageUrl){
      return (
        <article className="blog">
			<a onClick={props.onClick}>
			<figure>
			  <a onClick={props.onClick}><img alt="  No Image" className="img-fluid" src={post.imageUrl} />
				<div className="preview"><span>Read more</span></div>
			  </a>
			</figure>
			<div className="post_info">
			  <h2><a onClick={props.onClick}>{post.title}</a></h2>
			  <p><Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >{parse(post.description.replace(regex, " ").slice(0,150))}</Linkify>...</p>
			  <small>{'#' + post.tagName.split(' ').join() + ' #' + post.location.split(' ').join('')}</small>
			</div>
			</a>
      </article>
	  )
    }
	
  return (
      <article className="blog">
	  <a onClick={props.onClick}>
        <figure>
          <a onClick={props.onClick}><img alt="  No Image" className="img-fluid" src="https://storage.googleapis.com/share-australia/images/No-Image-Placeholder.svg.png" />
            <div className="preview"><span>Read more</span></div>
          </a>
        </figure>
        <div className="post_info">
          <h2><a onClick={props.onClick}>{post.title}</a></h2>
          <p><Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >{parse(post.description.replace(regex, " ").slice(0,150))}</Linkify>...</p>
		  <small>{'#' + post.tagName.split(' ').join() + ' #' + post.location.split(' ').join('')}</small>
        </div>
		</a>
      </article>
  )
}



export default Post
