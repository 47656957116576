import {Card} from 'antd';
import '../../styles/styles.css'
import React from 'react'
import Img from '../Image'

function TagCard(props) {

  const { Meta } = Card;

  return (
      <div hoverable bordered={false} onClick={props.onClick} className={'strip'}>
        <Img   height={150} src={props.imageUrl} preview={false} />
        {/*<Meta title={props.title} style={{marginTop:10}} />*/}
        <div className="t_item_title">
          <h3>{props.title}</h3>
        </div>
      </div>
  )
}


export default TagCard
