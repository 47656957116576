import { connect } from 'react-redux'
import Home from './Home'
import {getHomeInfo} from "../../actions/domainAction";
import {getPostList, clearPost} from "../../actions/postAction";

function mapStateToProps(state) {
  return {
    homeDomainInfo:state.domainReducer.homeDomainInfo,
    homePopular:state.domainReducer.homePopular,
    isLoading:state.domainReducer.isLoading,
    postList:state.postReducer.postList,
    postLoading:state.postReducer.postLoading,

  }
}

function mapDispatchToProps(dispatch) {
  return {
    getHomeInfo: () => dispatch(getHomeInfo()),
    clearPost: () => dispatch(clearPost()),
    getPostList: (domainId, tagId, page, pageSize) => dispatch(getPostList(domainId, tagId, page, pageSize)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
