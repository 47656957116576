import  {POST} from '../actions/ActionTypes'

const initialState = {
  postList:[],
  isCreatePost:false,
  createPostSuccess:false,
  postLoading:false,
  currentPost:null,

}

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST.GET_POST_LIST_SUCCESS:{
      return {
        ...state,
        postList: action.payload.body,
        postLoading:false,
      }
    }
    case POST.GET_POST_SUCCESS:{
      return {
        ...state,
        currentPost: action.payload.body,
      }
    }
    case POST.POST_CLEAR:{
      return {
        ...state,
        postList: [],
      }
    }
    case POST.GET_POST_LIST_START:{
      return {
        ...state,
        postLoading: true,
      }
    }
    case POST.CREATE_POST_START:{
      return {
        ...state,
        isCreatePost: true,
      }
    }
    case POST.CREATE_POST_END:{
      return {
        ...state,
        isCreatePost: false,
      }
    }
    case POST.CREATE_POST_SUCCESS:{
      return {
        ...state,
        createPostSuccess: true,
      }
    }
    default:
      return state

  }
}
export default domainReducer
