import { Spin, Button, BackTop, Divider} from 'antd';
import {  Row, Col } from 'react-bootstrap';
import '../../styles/styles.css'
import Post from '../../components/post'
import TagCard from '../../components/tag-card/TagCard'
import Loading from '../../components/loading'
import Header from '../../components/header/HeaderContainer'

import React, { useEffect, useState } from 'react'
import {Adsense} from '@ctrl/react-adsense';
import Footer from "../../components/footer";

import { getDomainName } from "../../untils/domain";

function SubHome(props) {
  const domain = getDomainName();



  const [postList, setPostList] = useState([])
  const [pageSize] = useState(100)
  const [isDomain, setIsDomain] = useState(false)
  const [isMorePost, setIsMorePost] = useState(true)


  const ACTIVE_STATE = {
    share:'share',
    //browse:'browse',
    discover:"discover"
  }


  const [activeState, setActiveState] = useState(ACTIVE_STATE.discover)

  const [modalShow, setModalShow] = useState(false)

  useEffect(()=>{
    if(JSON.stringify(props.match.params ) == '{}'){
      props.getDomainInfo(domain, "")
    } else {
      props.getDomainInfo(domain, props.match.params.domainFolder)
    }

    props.getTagList()

  }, [])



  useEffect(()=>{

    if(JSON.stringify(props.postList) != "[]"){

      setPostList(postList.concat(props.postList))

      if(props.postList.length < pageSize){
        setIsMorePost(false)
      } else {
        setIsMorePost(true)
      }

      props.clearPost()


    }
  }, [props.postList])


  useEffect(()=>{
    if(props.domainInfo && !isDomain){
      setIsDomain(true)
      props.getPostList(props.domainInfo.id, 0, 1, pageSize)
    }

  }, [props.domainInfo])


  useEffect(()=>{
    if((props.multipleDomainInfo && props.multipleDomainInfo.length > 1) && (props.location.search=='') && (!props.domainInfo || !props.domainInfo.domainFolder )){
      setModalShow(true)
    }

  }, [props.multipleDomainInfo])



  const { domainInfo , isLoading} = props


  const renderTagList = () => {
    let result = []


    props.tagList.forEach((tag) => {


      result.push(
          <Col md={3} sm={6} xs={12} style={{marginBottom:10}} key={tag.id}>
            <TagCard  imageUrl={ activeState == ACTIVE_STATE.share ? tag.shareImageUrl : tag.browseImageUrl} title={tag.displayName} onClick={()=>{
              const params = {
                tag: tag,
                domainInfo:domainInfo
              }


              const pre_url = props.match.params.domainFolder ? '/' + props.match.params.domainFolder  : ''


              const path = {
                pathname:pre_url + '/post/create',
                state:params,
              }




              if(activeState === ACTIVE_STATE.browse){
                path.pathname = pre_url + '/posts/' + tag.id +'/' + tag.displayName
              }

              props.history.push(path);

            }} />
          </Col>
      )
    })
    return result
  }


  const renderLoadingButton = () => {

    if(!isMorePost){
      return  null
    }


    if(props.postLoading){
      return  (
          <Divider  plain>
            <Spin size={'large'} />
          </Divider>
      )
    }



    return (
        <Divider  plain>
          <Button shape="round"  type="primary"  onClick={()=>{
            console.log(postList.length/pageSize + 1)
            props.getPostList(props.domainInfo.id, 0, postList.length/pageSize + 1, pageSize)
          }} style={{ fontWeight:'bold', backgroundColor:'#589442', borderColor:'#589442'}}>
            Show More
          </Button>
        </Divider>
    )
  }



  const renderPostList = () => {


    const result = []

    postList.forEach(  post => {
      if (post.id > 3) {
	  result.push(  <div className={'col-md-3'}>
        <Post post={post} onClick={()=>{

          const params = {
            post: post,
            domainInfo:props.domainInfo
          }

          const pre_url = props.match.params.domainFolder ? '/' + props.match.params.domainFolder  : ''

          const path = {
            pathname:pre_url + '/postDetail/' + post.id,
            state:params,
          }

          props.history.push(path);
        }
        }/>
      </div>)
	  }
    } )

    return (
        <Row>
          {result}
          {renderLoadingButton()}
        </Row>
    )
  }

  const renderAd = () => {


    const result = []
    const seenIds = new Set(); // Set to track seen ids
	/*

	*/
    postList.forEach(  post => {
		
	  
	  if (post.id < 5 && !seenIds.has(post.id) ) {
	  seenIds.add(post.id); // Add id to set
      result.push(  <div className={'col-md-6'}>
        <Post post={post} onClick={()=>{

          const params = {
            post: post,
            domainInfo:props.domainInfo
          }

          const pre_url = props.match.params.domainFolder ? '/' + props.match.params.domainFolder  : ''

          const path = {
            pathname:pre_url + '/postDetail/' + post.id,
            state:params,
          }

          props.history.push(path);
        }
        }/>
	</div>) }
    } 
	)

    return (

        <Row>
          {result}
        </Row>
    )
  }

  const renderList = () => {

    if(activeState == ACTIVE_STATE.browse){
        return  (
		
            <Row>
              {  renderTagList()}
            </Row>
        )
    }


    if(activeState == ACTIVE_STATE.discover){
	  return  [            <Row style={{marginRight:3, marginLeft:3,marginBottom:3}}>
              <Col className="col-3">
                <div style={{textAlign:'left'}}>
                  <span><em></em></span>
                  <h2 style={{ color: 'red' }}>Premium Posts</h2>
                </div>
              </Col>
            </Row>,renderAd(), <hr key="hr1" />,          <div className={"container margin_90_10"}>
            <Row style={{marginRight:3, marginLeft:3}}>

              <Col className="col-3">
                <div className="main_title version_2" style={{textAlign:'left'}}>
                  <span><em></em></span>
                  <h2>Community Posts</h2>
                </div>
              </Col>

            </Row>
          </div>, renderPostList()];
       
    }

    if(activeState == ACTIVE_STATE.share){
      return  (
          <Row>
            {  renderTagList()}
          </Row>
      )
    }

    return  []

  }


  const renderGoogleAds = () => {

    if(!domainInfo || !domainInfo.googleAds){
      return null
    }

    return (
        <Row style={{marginLeft:0, marginRight:0, marginTop:0, marginBottom:50, backgroundColor:'#c6c6c6'}}>
          <Col xxl={12}>
            <Adsense
                client='ca-pub-0604507048194960'
                slot='7573646394'
            />
          </Col>
        </Row>
    )
  }


  const renderBody = () => {
    // if(isLoading){
    //   return (
    //       <Loading disabled={!isLoading} />
    //   )
    // }

    if(modalShow){
      return (
          <main className="bg_gray pattern">

            <div className="container margin_60_40">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="box_booking_2">
                    <div className="head">
                      <div className="title">
                        <h3>PLEASE SELECT A STATE</h3>
                      </div>
                    </div>
                    <div className="main">
                      <div id="confirm">
                        <div className="icon icon--order-success svg add_bottom_15">
                          {renderSurburbButton()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
      )
    }


    return (
        <div>

          <div className="page_header element_to_stick">
            <div className="container">
              <div className="row" style={{textAlign:"left"}}>
                <div className="col-xl-2 col-lg-3 col-md-3 d-none d-md-block">
                  <div className="breadcrumbs">
                    <ul>
                      <li>Home</li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-7  ">
                </div>
              </div>
            </div>
          </div>

          <div>
            {renderGoogleAds()}
          </div>

          <div className={'container margin_detail'}>
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a  className={activeState == ACTIVE_STATE.share ? 'nav-link active' :'nav-link'} data-toggle="tab" role="tab"  onClick={() => { setActiveState(ACTIVE_STATE.share)}} >Create Post</a>
              </li>
              <li className="nav-item">
                <a   className={activeState == ACTIVE_STATE.discover ? 'nav-link active' :'nav-link'} data-toggle="tab" role="tab"  onClick={() => { setActiveState(ACTIVE_STATE.discover)}} >Explore Post</a>
              </li>
            </ul>
			
            <Row style={{marginTop:40, marginRight:2, marginLeft:2}}>
              {renderList()}
            </Row>
          </div>

        </div>
    )

  }

  const renderSurburbButton = () => {

    const result = []

    if(props.multipleDomainInfo.length > 0){
      props.multipleDomainInfo.forEach((domain)=>{
        result.push(
            <a className={'btn_1'} style={{marginRight:10}}  onClick={()=>{
          const url = 'http://' + domain.domainName + '/' + (domain.domainFolder ? domain.domainFolder + '/home': 'home')
          window.location.href = url + '?redirect=true'
        }}>{domain.state} {domain.postCode ? domain.postCode : ''}</a>

        )
      })

    }




    return result

  }

  return (
      <div>
        <Header domainFolder={props.match.params.domainFolder} />

        {renderBody()}

        <Footer />
        <BackTop duration={20} />
      </div>

  )
}


export default SubHome
