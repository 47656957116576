
import {Layout, Upload, Input,Tag, Button, message, Breadcrumb, Affix} from 'antd';
import { Container, Row, Col } from 'react-bootstrap';

import {Editor, EditorState, convertToRaw} from 'draft-js';
import  {convertToHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import '../../styles/styles.css'
import React, {useEffect, useState} from "react";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import ReCAPTCHA from "react-google-recaptcha";

import Header from '../../components/header/HeaderContainer'
import {Adsense} from '@ctrl/react-adsense';
import ImgCrop from 'antd-img-crop';
import Footer from "../../components/footer";


function CreatePost(props) {


  const { Content} = Layout;

  const [domainInfo] = useState(props.history.location.state.domainInfo)
  const [tag] = useState(props.history.location.state.tag)

  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [imageUrl, setImageUrl] = useState(null)

  const [recaptchaInstance, setRecaptchaInstance] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)

  const [tagInputVisible, setTagInputVisible] = useState(false)
  const [tagInput, setTagInput] = useState(null)
  const [newTagId, setNewTagId] = useState(null)






  useEffect(()=>{
    setNewTagId(props.newTagId)

  }, [props.newTagId])


  const createPost = () => {
    const params = {
      did:domainInfo.id,
      tid: tag.tagName === 'others' ?  newTagId : tag.id,
      title: title,
      description: convertToHTML(description.getCurrentContent()),
      imageUrl:imageUrl
    }
    props.createPost(params, (id) => {
      window.history.go(-1)
    })
  }


  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(`Image must smaller than 2MB!`)
    }
    return   isLt2M;
  }


  const uploadProps = {
    name: 'file',
    action: 'https://api.shareaustralia.com.au/file/upload',
    showUploadList:false,
    listType:'picture-card',
    onChange(info) {
      if (info.file.status === 'uploading'){
        setUploadLoading(true)
      }
      if (info.file.status === 'done') {
        setUploadLoading(false)
        setImageUrl(info.file.response.body)
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
  };


  const uploadButton = () => {
    if(uploadLoading){
      return <LoadingOutlined />
    }

    return  <PlusOutlined />
  }

  const createTag = () => {
    props.createTag(tagInput)
    setTagInputVisible(false)
  }

  const renderTag = () => {
    if(tag.tagName === 'others' && !tagInputVisible && (tagInput == '' || !tagInput)){
      return <span>
        {/*<Tag color="#3b5999">#{tag.displayName.split(' ').join()}</Tag>*/}
        <Tag onClick={()=>{
          setTagInputVisible(true)
        }} className="site-tag-plus" color="#3b5999"> <PlusOutlined /> Create Tag </Tag>
      </span>
    } else if (tag.tagName === 'others' && tagInputVisible) {
      return <span>
        {/*<Tag color="#3b5999">#{tag.displayName.split(' ').join()}</Tag>*/}
    <Input

        type="text"
        size="small"
        style={{ width: 78 }}
        value={tagInput}
        onChange={(e)=>{
          setTagInput(e.target.value.split(' ').join(''))
        }}
        onBlur={createTag}
        onPressEnter={createTag}
    />
      </span>
    } else if(tagInput != '' && tagInput){
      return  <span> <Tag color="#3b5999"
          closable
          onClose={e => {
           setTagInput(null)
          }}
      >
        {tagInput}
      </Tag></span>
    }

    return  <span><Tag color="#3b5999">#{tag.tagName.split(' ').join()}</Tag></span>
  }

  const submitVerification = () => {

    return  !recaptchaInstance || !description || !title  || (description == '' || title == '')
  }

  const renderBreadcrumb = () => {
    return (
        <Breadcrumb>
          <Breadcrumb.Item> <a onClick={()=>{
            window.history.go(-1)
          }}>GO BACK</a></Breadcrumb.Item>
        </Breadcrumb>
    )
  }

  const renderGoogleAds = () => {
    if(!props.domainInfo || !props.domainInfo.googleAds){
      return null
    }
    return (
        <Adsense
            client='ca-pub-0604507048194960'
            slot='5599067266'
        />
    )
  }



  return (
      <>
        <Header domainFolder={props.match.params.domainFolder} />



        <div className="page_header element_to_stick">
          <div className="container">
            <div className="row" style={{textAlign:"left"}}>
              <div className="col-xl-2 col-lg-3 col-md-3 d-none d-md-block">
                <div className="breadcrumbs">
                  <ul>
                    <li><a style={{textDecoration:'underline'}} onClick={()=>{


                      const url = 'https://' + props.domainInfo.domainName + '/' + (props.domainInfo.domainFolder ? props.domainInfo.domainFolder + '/home': 'home')

                      window.location.href = url + '?redirect=true'

                    }}>Home</a></li>
                    <li>Create Post</li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-7  ">
              </div>
            </div>
          </div>
        </div>


        <div className="container margin_30_40">
          <div className="row">
            <div className="col-lg-9">
              <div className={'blank_input'} style={{marginTop:10}}>
                <Input  placeholder="Title" size='large' bordered={false} value={title} onChange={(e)=>{
                  setTitle(e.target.value)
                }} />
              </div>

              <div className={'blank_input'} style={{marginTop:10}}>
                <Editor placeholder="Description"  editorState={description} onChange={(state) => {
                  setDescription(state)
                }} />
                {/*<Editor  */}
                {/*          value={description} onChange={(e)=>{*/}
                {/*  setDescription(e)*/}
                {/*}}*/}

                {/*/>*/}
              </div>


              <div className={'blank_input'} style={{marginTop:10, textAlign:'left'}} >
                <Row>
                  <Col xxl={2}>Post Image <span style={{fontSize:12}}>(optional)</span></Col>
                  <Col xxl={10}>
                    <ImgCrop rotate aspect={2}>
                      <Upload {...uploadProps}       beforeUpload={beforeUpload}>
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                      </Upload>
                    </ImgCrop>
                  </Col>
                </Row>
              </div>

              <div className={'blank_input'} style={{marginTop:10, textAlign:'left', paddingBottom:10}} >
                <Row>
                  <Col xxl={2}>Post Tag</Col>
                  <Col xxl={10}>
                    <Tag color="#55acee">#{domainInfo.location}</Tag> {renderTag()}
                  </Col>
                </Row>
              </div>



              <div className={'blank_input'} style={{marginTop:10, textAlign:'left', paddingBottom:10, borderBottom:0}} >
                <Row>
                  <Col xxl={2}></Col>
                  <Col xxl={5}>
                    <ReCAPTCHA
                        sitekey="6LfaSLUcAAAAACvBBdr6wW_QyGvF4B9d9UsBaFKg"
                        onChange={(value)=>{

                          setRecaptchaInstance(true)
                        }}
                    />
                  </Col>

                  <Col xxl={5} style={{textAlign:'right', paddingTop:20}}>
                    <Button size="large" style={{marginRight:20}} onClick={() => {
                      props.history.goBack()
                    }}>Cancel</Button>
                    <Button type="primary" size="large" style={{marginRight:20}} loading={props.isCreatePost}  disabled={submitVerification() || uploadLoading} onClick={createPost} >Create</Button>
                  </Col>
                </Row>


              </div>
            </div>
            <aside className="col-lg-3">
              {renderGoogleAds()}
            </aside>
          </div>
        </div>
        <Footer />
      </>
  )



}

export default CreatePost


