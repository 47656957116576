import { Spin} from 'antd';
import React, { useState } from 'react'

function Loading(props) {

  if(!props.disabled){
    return (
        <div className={'loading'}>

          <Spin size="large"   />

        </div>
    )
  }

  return null
}
export default Loading
