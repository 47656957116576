import { connect } from 'react-redux'
import Post from './Post'
import {getDomainInfo} from "../../actions/domainAction";
import {getTagList} from "../../actions/tagAction";
import {getPost} from "../../actions/postAction";

function mapStateToProps(state) {
  return {
    domainInfo:state.domainReducer.domainInfo,
    // // isLoading:state.domainReducer.isLoading,
    // // tagList:state.tagReducer.tagList,
    currentPost:state.postReducer.currentPost,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPost: (postId) => dispatch(getPost(postId)),
    // getTagList: () => dispatch(getTagList()),
    // getPostList: (domainId, tagId) => dispatch(getPostList(domainId, tagId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
