import React from 'react'
import { Switch, Route } from 'react-router-dom'
import HomeContainer from './screens/home/HomeContainer'
import PostsContainer from './screens/posts/PostsContainer'
import SubHomeContainer from './screens/sub-home/SubHomeContainer'
import CreatePostContainer from './screens/create-post/CreatePostContainer'
import PostContainer from './screens/post/PostContainer'
import SinglePageContainer from './screens/single-page/SinglePageContainer'
const Routers = () => (
  <main>
    <Switch>
      <Route exact path='/' component={HomeContainer}/>
      <Route exact path='/:domainFolder/home' component={SubHomeContainer}/>
      <Route exact path='/home' component={SubHomeContainer}/>
      <Route exact path='/page/:id' component={SinglePageContainer}/>



      <Route exact path='/post/create' component={CreatePostContainer}/>
      <Route exact path='/:domainFolder/post/create' component={CreatePostContainer}/>

      <Route exact path='/posts/:tagId/:tagDisplayName' component={PostsContainer}/>
      <Route exact path='/:domainFolder/posts/:tagId/:tagDisplayName' component={PostsContainer}/>

      <Route exact path='/postDetail/:postId' component={PostContainer}/>
      <Route exact path='/:domainFolder/postDetail/:postId' component={PostContainer}/>




      {/*<Route exact path='/redirect/:domainFolder' component={LoadingContainer}/>*/}
      {/*<Route exact path='/:domainFolder/home' component={SubHomeContainer}/>*/}
      {/*<Route exact path='/SubHome' component={SubHomeContainer}/>*/}
      {/*<Route exact path='/home' component={HomeContainer}/>*/}
      {/*<Route exact path='*' component={LoadingContainer}/>*/}
    </Switch>
  </main>
)
export default Routers
