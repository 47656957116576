export const DOMAIN = {
  GET_HOME_DOMAIN_INFO_SUCCESS:"DOMAIN/GET_HOME_DOMAIN_INFO_SUCCESS",
  GET_DOMAIN_INFO_SUCCESS:"DOMAIN/GET_DOMAIN_INFO_SUCCESS",
  SEARCH_DOMAIN_INFO_SUCCESS:"DOMAIN/SEARCH_DOMAIN_INFO_SUCCESS"
}

export const LOADING = {
  START:"LOADING/START",
  END:"LOADING/END",
  SEARCH_DOMAIN_START:"LOADING/SEARCH_DOMAIN_START",
  SEARCH_DOMAIN_END:"LOADING/SEARCH_DOMAIN_END",
}

export const TAG = {
  GET_TAG_LIST_SUCCESS:"TAG/GET_TAG_LIST_SUCCESS",
  CREATE_TAG_SUCCESS:"TAG/CREATE_TAG_SUCCESS",
}


export const POST = {
  GET_POST_LIST_START:"POST/GET_POST_LIST_START",
  GET_POST_LIST_SUCCESS:"POST/GET_POST_LIST_SUCCESS",
  CREATE_POST_START:"POST/CREATE_POST_START",
  CREATE_POST_END:"POST/CREATE_POST_END",
  CREATE_POST_SUCCESS:"POST/CREATE_POST_SUCCESS",
  POST_CLEAR:"POST/POST_CLEAR",
  GET_POST_START:"POST/GET_POST_START",
  GET_POST_SUCCESS:"POST/GET_POST_SUCCESS",
}


export const UPLOAD = {
  UPLOAD_START:"POST/UPLOAD_START",
  UPLOAD_END:"POST/UPLOAD_END",
  UPLOAD_SUCCESS:"POST/UPLOAD_SUCCESS",
  UPLOAD_FAIL:"POST/UPLOAD_FAIL",
}

export const SINGLE_PAGE = {
  GET_SINGLE_PAGE_SUCCESS:"SINGLE_PAGE/GET_SINGLE_PAGE_SUCCESS",
}
