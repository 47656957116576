import {Layout, Input, AutoComplete, Row, Col, Card, Spin} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../styles/styles.css'
import React, {  useState, useEffect } from 'react'
import {searchHomeInfo} from "../../actions/domainAction";

function SearchDomainInput(props) {


  const [options, setOptions] = useState(props.demandNotification)
  const [searchValue, setSearchValue] = useState(null)
  const width = window.innerWidth;
  const breakpoint = 481;


  useEffect(()=>{
    // console.log(props.searchDomainInfo)



    generateOptions()
  }, [props.searchDomainInfo])


  const getDomainUrl =  (domain) => {
    if(domain.domainFolder){
      return "http://" + domain.domainName + "/" + domain.domainFolder + "/home"
    }

    return "http://" + domain.domainName
  }

  const getDomainLable =  (domain) => {

    return  domain.location + ", " + domain.state + ' '  + domain.postCode
  }


  const generateOptions = () =>{
    let opt = []
    props.searchDomainInfo.forEach((domain) => {
      opt.push({value:getDomainUrl(domain), label:getDomainLable(domain)})
    })
    setOptions(opt)
  }


  // const renderSmallSearchDomainInput = () => {
  //   return (<div>11111</div>)
  // }


  const onChange = (value, option) => {
    if(JSON.stringify(option) == '{}'){
      setSearchValue(value)
      if(value.length > 2){
        props.searchHomeInfo(value)
      }

    } else {
      window.location.href = option.value;
    }



  }


  const renderSmallSearchDomainInput = () => {
    return (
        <AutoComplete
            value={searchValue}
            options={options}
            onChange={onChange}
        >


          <Input
              placeholder="Postcode or Suburb"
              prefix={<SearchOutlined />}
          />


        </AutoComplete>
    )
  }


  const renderFullSearchDomainInput = () => {
    return (
        <AutoComplete
            value={searchValue}
            options={options}
            style={{
              width: 500
            }}
            onChange={onChange}
            className={'custom-search-input'}
        >


          <Input
              size="large" placeholder=" Type in Postcode or Suburb to start ..."
              prefix={<SearchOutlined className={"icon_pin_alt"} fill={"#999999"} />}
              bordered={false}
          />


        </AutoComplete>
    )
  }


  const renderInput = () => {
    if(props.small){
      return (
          <div>
            { renderSmallSearchDomainInput()}
            <div style={{fontSize:12, fontStyle:'italic', fontWeight:'bold',  marginRight:-30, position:'absolute',  marginTop:35}} onClick={()=>{
              window.location.href='http://shareaustralia.com.au/'
            }}>
             <a> Powered by <img src={'https://storage.googleapis.com/share-australia/images/ShareAustraliaLogo.png'} width={100} /></a>
            </div>
          </div>
      )
    }
	
	if (width < breakpoint) {
		return renderSmallSearchDomainInput()
	}
	
    return renderFullSearchDomainInput()
  }

  return renderInput()
}


export default SearchDomainInput
