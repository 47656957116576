import { connect } from 'react-redux'
import SearchDomainInput from './SearchDomainInput'
import {searchHomeInfo} from "../../actions/domainAction";

function mapStateToProps(state) {
  return {
    searchDomainInfo:state.domainReducer.searchDomainInfo,
    // isLoading:state.domainReducer.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    searchHomeInfo: (searchString) => dispatch(searchHomeInfo(searchString)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchDomainInput)
