import {api} from "../untils/api";
import { SINGLE_PAGE} from './ActionTypes';


export function getSinglePage(id) {
  return dispatch => {
    api.get('/page?id=' + id) .then(response => {
      dispatch(loadingSinglePageSuccess(response.data))
    }).catch(err => {
      console.log(err) // eslint-disable-line no-console
    })
  }
}



function loadingSinglePageSuccess(data) {
  return {
    type: SINGLE_PAGE.GET_SINGLE_PAGE_SUCCESS,
    payload: data,
  }
}
