import {Breadcrumb, Layout, Tag} from 'antd';
import {  Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/header/HeaderContainer'
import '../../styles/styles.css'

import React, { useEffect, useState } from 'react'
import {HomeFilled, CarFilled} from '@ant-design/icons';
import {Adsense} from '@ctrl/react-adsense';
import Footer from "../../components/footer";

import Linkify from 'react-linkify';
import parse from "html-react-parser";

function Posts(props) {

  const { Content} = Layout;
  
  const [post, setPost] = useState(null)




  useEffect(()=>{
    // console.log(props.match.params.postId)
    props.getPost(props.match.params.postId)

  }, [])



  useEffect(()=>{
    setPost(props.currentPost)
    console.log(props.currentPost)
  }, [props.currentPost])



  const renderBreadcrumb = () => {
    return (
        <Breadcrumb>
          <Breadcrumb.Item> <a onClick={()=>{
            window.history.go(-1)
          }}>GO BACK</a></Breadcrumb.Item>
        </Breadcrumb>
    )
  }


  const renderImage = () => {

    if(post.imageUrl){
      return (
          <figure><img alt="" className="img-fluid" src={post.imageUrl} /></figure>
      )
    }
  }



  const renderGoogleAds = () => {
    if(!props.domainInfo || !props.domainInfo.googleAds){
      return null
    }
    return (
        <Adsense
            client='ca-pub-0604507048194960'
            slot='5599067266'
        />
    )
  }

  const renderBody = () => {

    if(!post){
      return  null
    }

    return (
        <>
          <div className="page_header element_to_stick">
            <div className="container">
              <div className="row" style={{textAlign:"left"}}>
                <div className=" col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                  <div className="breadcrumbs">
                    <ul>
                      <li><a style={{textDecoration:'underline'}} onClick={()=>{


                        if( !props.domainInfo || props.domainInfo.domainName == 'shareaustralia.com.au' && !props.domainInfo.domainFolder){
                          window.location.href = 'http://shareaustralia.com.au/'
                          return false
                        }



                        const url = 'http://' + props.domainInfo.domainName + '/' + (props.domainInfo.domainFolder ? props.domainInfo.domainFolder + '/home': 'home')

                        window.location.href = url + '?redirect=true'

                      }}>Home</a></li>
                      <li>{post.title}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3  ">
                </div>
              </div>
            </div>
          </div>


          <div className="container margin_30_40">
            <div className="row">
              <div className="col-lg-9">
                <div className="singlepost" style={{textAlign:'left'}}>
                  {renderImage()}

                  <h1>{post.title}</h1>
                  <div className="postmeta">
                    <ul style={{fontSize:'0.875rem'}}>
                      {/*<li><i className="icon_folder-alt"></i> {post.tagDisplayName.split(' ').join('')}</li>*/}
                      <li><i className="icon_pencil-edit"></i> {post.location.split(' ').join('')}</li>
                      <li><i className="icon_comment_alt"></i> {post.state.split(' ').join('')}</li>
                    </ul>
                  </div>

				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
                  <div className="post-content" style={{fontSize:'0.875rem', color:'#444444'}} >
					{parse(post.description)}
					<p>{'#' + post.tagName.split(' ').join() + ' #' + post.location.split(' ').join('')}</p>
                  </div>
				</Linkify>
				
                </div>

              </div>


              <aside className="col-lg-3">
                {renderGoogleAds()}

              </aside>
            </div>
          </div>
        </>
    )

  }




  return (
      <div>
        <Header domainFolder={props.match.params.domainFolder} />
        {renderBody()}
        <Footer />
      </div>
  )
}


export default Posts
